import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "index.css";
import "tailwindcss/lib/css/preflight.css";
import "react-phone-input-2/lib/style.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/HeroSection";
import Homepage from "pages/Homepage";
import PageNotFound from "components/PageNotFound";
import Footer from "components/Footer";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import Industry from "pages/Industry";
import PrivacyPolicy from "pages/PrivacyPolicy";
import InquiryPage from "pages/InquiryPage";
import ServicePage from "pages/Services/ServicePage";
import ThankYoupage from "pages/ThankYoupage";
import DefaultServicePage from "pages/Services/DefaultServicePage";
import Header2 from "components/headers/Header2";
import AdvisoryService from "pages/Services/AdvisoryService";
import FinancialReportsService from "pages/Services/FinancialReportsService";
import FinancialStrategies from "pages/Services/FinancialStrategies";
import PreparationSchedulesService from "pages/Services/PreparationSchedulesService";
import RegistrationService from "pages/Services/RegistrationService";
import BookkeepingService from "pages/Services/BookkeepingService";
import BasService from "pages/Services/BasService";
import BusinessStructure from "pages/Services/BusinessStructure";
import ATOService from "pages/Services/ATOService";
import FinancialStatments from "pages/Services/FinancialStatments";
import GSTPayement from "pages/Services/GSTPayement";
import IASService from "pages/Services/IASService";
import PayrollService from "pages/Services/PayrollService";
import TaxPlanning from "pages/Services/TaxPlanning";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import TaxReturns from "pages/Services/TaxReturns";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LessTEmAAAAAPHGaQXjkJaL6PM6wvv2g2lQt_Kn">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/services" element={<ServicePage />} />
          <Route
            exact
            path="/default-services"
            element={<DefaultServicePage />}
          />
          <Route exact path="/advisory-service" element={<AdvisoryService />} />
          <Route
            exact
            path="/financial-report-service"
            element={<FinancialReportsService />}
          />
          <Route
            exact
            path="/financial-strategies"
            element={<FinancialStrategies />}
          />
          <Route
            exact
            path="/preparation-schedule"
            element={<PreparationSchedulesService />}
          />
          <Route
            exact
            path="/registration-service"
            element={<RegistrationService />}
          />
          <Route
            exact
            path="/bookkeeping-service"
            element={<BookkeepingService />}
          />
          <Route exact path="/bas-service" element={<BasService />} />
          <Route
            exact
            path="/business-structure"
            element={<BusinessStructure />}
          />
          <Route exact path="/ato-service" element={<ATOService />} />
          <Route exact path="/gst-payment" element={<GSTPayement />} />
          <Route exact path="/ias-service" element={<IASService />} />
          <Route exact path="/payroll-service" element={<PayrollService />} />
          <Route exact path="/tax-planning" element={<TaxPlanning />} />
          <Route exact path="/tax-returns" element={<TaxReturns />} />
          <Route
            exact
            path="/financial-statements"
            element={<FinancialStatments />}
          />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/industry" element={<Industry />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/inquiry" element={<InquiryPage />} />
          <Route exact path="/thank-you" element={<ThankYoupage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>{" "}
    </GoogleReCaptchaProvider>
  );
}

export default App;
