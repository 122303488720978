import { React } from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import CookieConsent from "react-cookie-consent";

import LogoImage from "logo.png";
import { ReactComponent as MailIcon } from "assets/icons/email-01.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone-01.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location-01.svg";
import { Link } from "react-router-dom";

import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-900 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap  flex-col md:flex-row md:flex-nowrap justify-between`;

const Column = tw.div`lg:w-1/5 md:w-3/5 sm:w-3/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-gray-400 sm:mt-10 mt-5`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkStyle = tw.a`border-b-2 border-transparent text-gray-400 hocus:text-primary-600 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-48`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-600`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-gray-400 text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-0 ml-5`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-400 hover:bg-primary-300 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const handleLoaded = (_) => {
  //   window.grecaptcha.ready((_) => {
  //     window.grecaptcha
  //       .execute("6LessTEmAAAAAPHGaQXjkJaL6PM6wvv2g2lQt_Kn", {
  //         action: "homepage",
  //       })
  //       .then((token) => {
  //         // ...
  //         console.log("Verified");
  //       });
  //   });
  // };
  // useEffect(() => {
  //   // Add reCaptcha
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://www.google.com/recaptcha/api.js?render=6LessTEmAAAAAPHGaQXjkJaL6PM6wvv2g2lQt_Kn";
  //   script.addEventListener("load", handleLoaded);
  //   document.body.appendChild(script);
  // }, []);
  return (
    <Container>
      <FiveColumns className="smlscreen-align-center">
        <WideColumn>
          <LogoContainer>
            <Link to="/">
              <LogoImg src={LogoImage} alt="Fortune Accounts Logo" />
            </Link>
          </LogoContainer>
          <CompanyDescription>
            Leave Your Accounting Needs to Us - Trusted Experts in Perth. With
            Over a Decade of Experience, Your accounting is in Safe Hands
          </CompanyDescription>
          <CompanyDescription>
            <LocationIcon
              style={{ width: "20px", display: "inline", marginRight: "10px" }}
            />
            &nbsp;&nbsp;24, Vivid Chase, Dayton WA. 6055
          </CompanyDescription>
          <CompanyDescription>
            <MailIcon
              style={{ width: "20px", display: "inline", marginRight: "10px" }}
            />
            <a href="mailto:info@fortuneaccounts.com.au">
              &nbsp;&nbsp;info@fortuneaccounts.com.au
            </a>
          </CompanyDescription>
          <CompanyDescription>
            <PhoneIcon
              style={{ width: "20px", display: "inline", marginRight: "10px" }}
            />
            <a href="tel: +61 422 456 064">&nbsp;+61 422 456 064</a>
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>QUICK LINKS</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="/">
                <LinkStyle>Home</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/about-us">
                <LinkStyle>About Us</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="contact-us">
                <LinkStyle>Contact Us</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/inquiry">
                <LinkStyle>Make an Inquiry</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/privacy-policy">
                <LinkStyle>Privacy Policy</LinkStyle>
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>SERVICES WE OFFER</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="/advisory-service" state={{ service: "advisory" }}>
                <LinkStyle>Advisory Services</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link
                to="/bookkeeping-service"
                state={{ service: "bookkeeeping" }}
              >
                <LinkStyle>Bookkeeping Services</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/bas-service" state={{ service: "bookkeeeping" }}>
                <LinkStyle>Business Activity Statement (BAS)</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link
                to="/business-structure"
                state={{ service: "bookkeeeping" }}
              >
                <LinkStyle>Business Structures</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/ato-service" state={{ service: "bookkeeeping" }}>
                <LinkStyle>Dealing with the ATO</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/financial-report-service">
                <LinkStyle>Financial Reports</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/financial-statements">
                <LinkStyle>Financial Statements</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/financial-strategies">
                <LinkStyle>Financial Strategies</LinkStyle>
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>

        <Column>
          <ColumnHeading>&nbsp;</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link to="/gst-payment">
                <LinkStyle>GST Payment & Refund</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/ias-service">
                <LinkStyle>Instalment Activity Statement (IAS)</LinkStyle>I
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/payroll-service">
                <LinkStyle>Payroll Services</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/preparation-schedule">
                <LinkStyle>Preparation of Schedules</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/registration-service">
                <LinkStyle>Registrations</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/tax-planning">
                <LinkStyle>Tax Planning</LinkStyle>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/tax-returns">
                <LinkStyle>Tax Returns</LinkStyle>
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <CookieConsent
        location="none"
        buttonStyle={{ color: "#fff", background: "#3366ff" }}
        style={{
          bottom: "2%",
          boxShadow: " 0 0 10px 0 #aaaaaa",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "330px",
          // width: "100%",
          borderRadius: "10px",
          right: "2%",
          left: "unset",
          background: "#fff",
          color: "#000",
          paddingLeft: "unset",
          paddingRight: "unset",
        }}
        contentStyle={{
          flex: "1 0 auto",
          textAlign: "justify",
          color: "#4d4d4d",
        }}
        debug={false}
        cookieName="myAwesomeCookieName3"
        expires={365}
        buttonText="Accept"
        buttonClasses="btn-primary-1"
      >
        <span style={{ fontSize: "1.3rem", fontWeight: "500" }}>
          We Use Cookies
        </span>{" "}
        <br /> This site uses cookies to deliver our services and to ensure you
        get best experience. By continuing to use this site, you consent to our
        use of cookies and acknowledge that you have read and understand our{" "}
        <Link to="/privacy-policy">
          <u> Privacy Policy</u>
        </Link>
      </CookieConsent>
      <div className="btm-line"></div>
      <div className="copright relative">
        <p className="text-white text-center">
          Copyright 2023 Fortune Accounting & Taxation. All Rights Reserved. |
          <Link className="hocus:text-primary-600" to="/privacy-policy">
            {" "}
            Privacy Policy
          </Link>
        </p>
        <div className="absolute">
          <SocialLinksContainer>
            <SocialLink
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100092499608004"
            >
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink target="_blank" href="https://twitter.com">
              <TwitterIcon />
            </SocialLink> */}
            {/* <SocialLink target="_blank" href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </div>
      </div>
      <div
        className="g-recaptcha"
        data-sitekey="6LessTEmAAAAAPHGaQXjkJaL6PM6wvv2g2lQt_Kn"
        data-size="invisible"
      ></div>
    </Container>
  );
};
