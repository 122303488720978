import React from "react";
import { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import logo from "logo.png";
import ltrim from "validator/lib/ltrim";
import rtrim from "validator/lib/rtrim";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import PhoneInput from "react-phone-input-2";
import { URL } from "Config";
import { MetaTags } from "react-meta-tags";
import { contactUs } from "MetaTags";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Alert, Col, Row, Form, Container } from "react-bootstrap";
import { css } from "styled-components/macro"; //eslint-disable-line
import GoogleMap from "components/GoogleMap";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// Icons
import PhoneIcon from "assets/icons/phone-contact-01.svg";
import MailIcon from "assets/icons/mail-contact-01.svg";
import TimingIcon from "assets/icons/timing-contact-01.svg";
import LocationIcon from "assets/icons/location-contact-01.svg";

import breadImg from "assets/getintouch-brdcrms.jpg";
import Header2 from "components/headers/Header2";
import axios from "axios";
import { Link } from "react-router-dom";
import { useRecaptcha } from "components/ReCaptcha";
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:ml-12 lg:ml-16 md:order-last`
    : tw`md:mr-12 lg:mr-16 md:order-first`,
]);
const HighlightedText = tw.span`text-primary-600`;
const myStyle = {
  background: `url(${breadImg})`,
  backgroundSize: "cover",
};

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw.h1`mt-4 font-black text-primary-300 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(
  PrimaryButtonBase
)`bg-primary-400 text-white text-sm border-none sm:text-base sm:mt-8  sm:px-8 sm:py-4  text-primary-200 font-bold rounded shadow transition duration-300 hocus:bg-primary-200 hocus:text-white focus:shadow-outline`;

const to_email = "info@fortuneaccounts.com.au";
const cc = "arkayappsseo@gmail.com";
// const to_email = "amit@arkayapps.com";
// const cc = "amit@arkayapps.com";
const title = "Website Inquiry";
const subject = "Contact Form - Fortune";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const generateToken = useRecaptcha();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Fortune</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }

        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            margin: 10px 0;
        }

        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:200px;
            height:auto;
        }

        .text-center {
            text-align: center !important;
        }

        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }

        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }

        .card-header {
            background: #136BCE;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }

        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }

        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #136BCE;
        }

        .card-body p strong {
            color: #333;
        }

        .card-body p strong.heading {
            color: #136BCE;
        }

        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>

<body>
    <div class="content">
        <div class="text-center">
            <img src="https://fortuneaccounts.com.au/logo.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received Message From Contact Form !</p>
        </div>
        <div class="card-body">
            <p><strong>Name : </strong>${name}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Mobile No: </strong>${mobile}</p>
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 Fortune Accounting & Taxation Services</p>
        </div>
    </div>
</body>

</html>`;

  const mailError = <p>{error}</p>;

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    setError(null);
  };
  const handleEmail = (e) => {
    var value = e.target.value;
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMobile = (value) => {
    setMobile(value);
    setError(null);
  };
  const handleMessage = (e) => {
    const value = e.target.value;
    setMessage(value);
    setError(null);
  };

  const sendMail = (e) => {
    e.preventDefault();
    if (name === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
    } else if (email === null) {
      setError(<Alert variant={"danger"}>Email Required</Alert>);
    } else if (mobile === null) {
      setError(<Alert variant={"danger"}>Phone Required</Alert>);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
    } else {
      setDisabled(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("cc", cc);
      formData.append("to_email", to_email);
      formData.append("template", template);
      formData.append("subject", subject);
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const url = URL + "/send-mail";
      axios
        .post(url, formData, headerConfig)
        .then((response) => {
          console.log(response);
          document.getElementById("contact-us").reset();
          setDisabled(false);
          navigate("/thank-you");
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response) {
            console.log(error.response);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{error.message}</Alert>);
          setDisabled(false);
        });
    }
  };

  const handleRecaptchaVerify = async (e) => {
    e.preventDefault();
    // setLoading(true);
    generateToken((token) => {
      const recaptchaData = new FormData();
      const headerConfig = {
        headers: {
          accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
        },
      };
      recaptchaData.append(
        "secret",
        "6LessTEmAAAAAAwfMJu3IFMgPGFN6mD09Tgqracz"
      );
      recaptchaData.append("response", token);

      axios
        .post(
          "https://api.sendmail.adhyaynam.in/recaptcha-verify",
          recaptchaData,
          headerConfig
        )
        .then((res) => {
          console.log("res.data.score", res.data.score);
          if (res.data.score > 0.5) {
            sendMail(e);
          } else {
            toast.error(res.data["error-codes"][0]);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          // setError(<Alert variant={'danger'}>'Please Try Again Later.'</Alert>);
          toast.error("Please Try Again Later.");
          // setLoading(false);
        });
    });
  };
  return (
    <>
      <MetaTags>
        <title>{contactUs.title}</title>
        <meta name="description" content={contactUs.description} />
        <link rel="canonical" href={contactUs.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>
            CONTACT
            <HighlightedText> US</HighlightedText>
          </Heading>
          <h4>
            Fortune provides top-notch accounting and tax services with
            transparency, accuracy, and timeliness. <br /> Our experts offer
            tailored solutions to meet the unique needs of each client.
          </h4>
        </div>
      </section>
      <Container className="my-5">
        <Row>
          <Col md={6} xs={12} className="contact-info my-auto">
            <Heading>Ask Us Anything. Anytime.</Heading>
            <div className="icon mt-4"></div>
            <p>
              Let us handle the numbers so you can focus on growing your
              business.
            </p>
            <div className="icon mt-5">
              <img
                src={LocationIcon}
                style={{ width: "22px", marginRight: "25px" }}
                alt=""
              />
              <p>24 Vivid Chase, Dayton WA 6055</p>
            </div>

            <div className="icon mt-3">
              <img
                src={PhoneIcon}
                style={{ width: "22px", marginRight: "25px" }}
                alt=""
              />
              <p>
                <a href="tel:+61 422 456 064">+61 422 456 064</a>
              </p>
            </div>

            <div className="icon mt-3">
              <img
                src={MailIcon}
                style={{ width: "22px", marginRight: "25px" }}
                alt=""
              />
              <p>
                <a href="mailto:info@fortuneaccounts.com.au">
                  info@fortuneaccounts.com.au
                </a>
              </p>
            </div>
            <div className="icon mt-3">
              <img
                src={TimingIcon}
                style={{ width: "22px", marginRight: "25px" }}
                alt=""
              />
              <p>
                Open Hours <br />
                Monday - Friday <br /> 09:00 AM - 05:30 PM
              </p>
            </div>
          </Col>
          <Col md={6}>
            <Form
              // ref={form}
              className="contact-form"
              id="contact-us"
              onSubmit={handleRecaptchaVerify}
            >
              <Row>
                <Col className="mt-3 mb-3" md={6}>
                  <input
                    type="text"
                    name="name"
                    required
                    className="form-control"
                    // value={fname}
                    minLength="3"
                    maxLength="50"
                    inputProps={{ minLength: 3, maxLength: 50 }}
                    onChange={handleName}
                    placeholder="Name"
                  />
                </Col>
                <Col className="mt-3 mb-3" md={6}>
                  <input
                    name="email"
                    type="email"
                    required
                    className="form-control"
                    value={email}
                    minLength="3"
                    maxLength="50"
                    onChange={handleEmail}
                    placeholder="Email"
                  />
                </Col>
                <Col md={6}>
                  <PhoneInput
                    country={"au"}
                    onChange={handleMobile}
                    inputProps={{ minLength: 3, maxLength: 18, required: true }}
                  />
                </Col>
                <Col className="mt-2" md={12}>
                  <textarea
                    name="message"
                    message="message"
                    rows="4"
                    placeholder="Message*"
                    className="form-control"
                    required
                    inputProps={{ minLength: 3, maxLength: 200 }}
                    onChange={handleMessage}
                  ></textarea>
                </Col>
                <Col className="mt-2 mb-2" md={12}>
                  <SubmitButton type="submit">Send Message</SubmitButton>
                  <br />
                  <br />
                </Col>
                {mailError}
              </Row>
              <Description>
                * We value your privacy and never share your information with
                third parties. Your trust is important to us.
              </Description>
            </Form>
          </Col>
        </Row>
      </Container>
      <div className="mt-2 mb-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54222.90038685264!2d115.848434816862!3d-31.854085359889634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32b759c10577ad%3A0x2acf976aa75ef93b!2sFortune%20Accounting%20%26%20Taxation%20Services!5e0!3m2!1sen!2sin!4v1685080771197!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <ScrollToTop
          smooth
          component={<MySVG style={{ padding: "5px" }} />}
          top={800}
        />
      </div>
    </>
  );
};
